import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  html {
    --black: #010720;
    // --black2: #020305;
    --black2: #000000;
    --white: #ffffff;
    // --white2: #030d37;
    --white2: #FF6F61;
    overflow-x: hidden;
    width: 100vw;
  }

  body {
    position: relative;
    overflow-x: hidden;
    width: 100vw;
    margin: 0;
    padding: 0;
    background: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.black};
    font-family: 'new-hero', sans-serif;
    font-weight: 400;
  }

  body::-webkit-scrollbar {
    width: 8px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--white2) var(--black2);
  }
  body::-webkit-scrollbar-track {
    background: var(--black2);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--white2) ;
    border-radius: 0;
    border: 0 solid var(--black2);
  }

  ${({ theme }) => theme.media.xxl} {
    body {
      background: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.black};
    }
  }
`
