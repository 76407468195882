import React from 'react'
import PropTypes from 'prop-types'
import TransitionLink from 'gatsby-plugin-transition-link'

import logoIcon from '@images/logo.svg'

import { Wrapper, Icon } from './styles.js'

const Logo = ({ isOpened, white, footer }) => {
  return (
    <Wrapper>
      <TransitionLink
        to="/"
        title="Home"
        exit={{
          length: 1.2,
          trigger: () => {
            localStorage.setItem('color', '#ffffff')
          },
        }}
        entry={{
          delay: 1.2,
        }}
      >
        <Icon
          isOpened={isOpened}
          footer={footer}
          white={white}
          src={logoIcon}
        />
        {!footer && <h1>Mola - Digital Creative Agency</h1>}
      </TransitionLink>
    </Wrapper>
  )
}

Logo.propTypes = {
  isOpened: PropTypes.bool,
  footer: PropTypes.bool,
}

Logo.defaultProps = {
  isOpened: false,
  footer: false,
}

export default Logo
