import styled from 'styled-components'
import SVG from 'react-inlinesvg'

export const Wrapper = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;

  li {
    margin-right: 12px;

    &:last-child {
      margin: 0;
    }

    path {
      transition: fill 0.3s ease;
    }

    a {
      &:hover {
        path:nth-child(1) {
          fill: ${({ theme }) => theme.colors.white};
        }

        path:nth-child(2) {
          fill: ${({ theme }) => theme.colors.purple};
        }

        path:nth-child(3) {
          fill: ${({ theme }) => theme.colors.purple};
        }

        path:nth-child(4) {
          fill: ${({ theme }) => theme.colors.purple};
        }
      }
    }
  }

  ${({ theme }) => theme.media.l} {
    li {
      margin-right: 24px;

      &:last-child {
        margin: 0;
      }
    }
  }
`

export const Icon = styled(SVG)`
  position: relative;
  display: flex;
  flex-basis: 20px;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;

  &:hover {
    > path:nth-child(1) {
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  ${({ theme }) => theme.media.s} {
    width: 40px;
    height: 40px;
  }

  ${({ theme }) => theme.media.l} {
    width: 48px;
    height: 48px;
  }
`
