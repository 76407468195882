import React from 'react'
import { ThemeProvider } from 'styled-components'

import '@theme/sanitize.css'
import '@theme/fonts.css'
import { mainTheme } from '@theme/theme'

import Header from '@organisms/Header'

import { GlobalStyle } from './styles.js'

const Outter = ({ children }) => {
  return (
    <ThemeProvider theme={mainTheme}>
      <GlobalStyle />
      <Header />
      {children}
    </ThemeProvider>
  )
}

export default Outter
