import styled from 'styled-components'
import SVG from 'react-inlinesvg'

export const Wrapper = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  h1 {
    position: absolute;
    top: 0;
    left: 0;
    text-indent: -9990px;
  }
`

export const Icon = styled(SVG)`
  position: relative;
  display: flex;
  flex-basis: 20px;
  justify-content: center;
  align-items: center;
  width: calc(233px * 0.5);
  height: calc(75px * 0.5);

  path {
    fill: ${({ theme, white, footer }) =>
      footer || white ? theme.colors.white : theme.colors.black};
    transition: fill 0.3s ease;
  }

  ${({ theme }) => theme.media.m} {
    path {
      fill: ${({ theme, white }) => white && theme.colors.black};
    }
  }

  ${({ theme }) => theme.media.l} {
    width: calc(233px * 0.7);
    height: calc(75px * 0.7);

    path {
      fill: ${({ theme, footer }) =>
        footer ? theme.colors.purple : theme.colors.black};
      ${({ theme, isOpened }) => isOpened && `fill: ${theme.colors.white};`}
    }
  }
`
