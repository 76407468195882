import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import TransitionLink from 'gatsby-plugin-transition-link'
import {
  Wrapper,
  Content,
  Hamburger,
  MenuIcon,
  Menu,
  SecondMenu,
  NavList,
  NavListItem,
} from './styles.js'
import IconMenu from '@images/menu.svg'

import Logo from '@atoms/Logo'
import Socials from '@atoms/Socials'

import fbIcon from '@images/socials/facebook.svg'
import inIcon from '@images/socials/instagram.svg'
import ytIcon from '@images/socials/youtube.svg'

const Header = ({ active, navItems }) => {
  const [menuOpened, setMenuOpened] = useState(false)
  const [scrollOnTop, setScrollOnTop] = useState(true)
  const [showMenu, setShowMenu] = useState(false)

  const headerData = useStaticQuery(graphql`
    query HeaderQuery {
      datoCmsGeneralInfo {
        facebook
        instagram
        youtube
        email
        phone
      }
    }
  `)

  const toggleMenu = () => {
    let distanceFromTop = window.pageYOffset

    return function () {
      let newDistanceFromTop = window.pageYOffset
      if (newDistanceFromTop < 50 || distanceFromTop >= newDistanceFromTop) {
        setShowMenu(true)
      } else {
        setShowMenu(false)
      }
      if (newDistanceFromTop < 50) {
        setScrollOnTop(true)
      } else {
        setScrollOnTop(false)
      }
      distanceFromTop = newDistanceFromTop
    }
  }

  useEffect(() => {
    setTimeout(() => setShowMenu(true), 2800)
    window.addEventListener('scroll', toggleMenu())
    return window.removeEventListener('scroll', toggleMenu())
  }, [])

  useEffect(() => {
    menuOpened
      ? (document.querySelector('html').style.overflowY = 'hidden')
      : (document.querySelector('html').style.overflowY = 'visible')
  }, [menuOpened])

  const handleMenuClick = () => {
    setMenuOpened(false)
    !scrollOnTop && setShowMenu(false)
  }

  const navItemsContent = [
    { id: '001', color: '#ffffff', name: 'home', url: '/' },
    { id: '002', color: '#4100F5', name: 'us', url: '/us' },
    { id: '003', color: '#4100F5', name: 'work', url: '/work' },
    { id: '004', color: '#4100F5', name: 'contact', url: '/contact' },
  ]

  return (
    <Wrapper active={active} scrolled={!scrollOnTop} menuShowed={showMenu}>
      <Content>
        <Logo isOpened={menuOpened} white={scrollOnTop} />
        <Hamburger
          isOpened={menuOpened}
          onMouseDown={() => setMenuOpened(!menuOpened)}
        >
          <MenuIcon src={IconMenu} />
        </Hamburger>
      </Content>
      <Menu isOpened={menuOpened}>
        <NavList isOpened={menuOpened} onClick={handleMenuClick}>
          {navItemsContent.map(item => (
            <NavListItem isOpened={menuOpened}>
              <TransitionLink
                to={item.url}
                title={item.name}
                exit={{
                  length: 1.2,
                  trigger: () => {
                    localStorage.setItem('color', item.color)
                  },
                }}
                entry={{
                  delay: 1.2,
                }}
                data-text={item.name}
              >
                <span>{item.name}</span>
              </TransitionLink>
            </NavListItem>
          ))}
        </NavList>
      </Menu>
      <SecondMenu isOpened={menuOpened}>
        <div>
          <h3>contact</h3>
          <ul>
            {headerData.datoCmsGeneralInfo.phone && (
              <li>P // {headerData.datoCmsGeneralInfo.phone}</li>
            )}
            <li>E // {headerData.datoCmsGeneralInfo.email}</li>
          </ul>
        </div>
        <Socials
          data={[
            {
              id: 'fb',
              name: 'Facebook',
              icon: fbIcon,
              to: headerData.datoCmsGeneralInfo.facebook,
            },
            {
              id: 'in',
              name: 'Instagram',
              icon: inIcon,
              to: headerData.datoCmsGeneralInfo.instagram,
            },
            {
              id: 'yt',
              name: 'Youtube',
              icon: ytIcon,
              to: headerData.datoCmsGeneralInfo.youtube,
            },
          ]}
        />
      </SecondMenu>
    </Wrapper>
  )
}

Header.propTypes = {
  active: PropTypes.bool,
  navItems: PropTypes.arrayOf(PropTypes.object),
}

Header.defaultProps = {
  active: false,
  navItems: [],
}

export default Header
