import { colors } from './colors'

export const mainTheme = {
  colors,
  media: {
    xs: '@media (min-width: 368px)',
    s: '@media (min-width: 768px)',
    m: '@media (min-width: 1024px)',
    l: '@media (min-width: 1280px)',
    xl: '@media (min-width: 1520px)',
    xxl: '@media (min-width: 1920px)',
  },
  margins: {
    maxWidth: '1700px',
    phonePadding: `25px`,
    tabletPadding: `30px`,
    desktopPadding: `80px`,
    bigDesktopPadding: `120px`,
    tvPadding: `170px`,
  },
  // Padding for header
  headerPaddings: {
    xs: `20px`,
    s: `25px`,
    m: `30px`,
    l: `35px`,
    xl: `40px`,
    xxl: `45px`,
  },
  // Page content paddings
  paddings: {
    xs: `20px`,
    s: `25px`,
    m: `45px`,
    l: `70px`,
    xl: `80px`,
    xxl: `90px`,
  },
  // Hero Section padding
  heroPaddings: {
    xss: `50px`,
    xs: `70px`,
    s: `80px`,
    m: `180px`,
    l: `240px`,
    xl: `240px`,
    xxl: `240px`,
  },
}
