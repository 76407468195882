import React from 'react'
import PropTypes from 'prop-types'
import TransitionLink from 'gatsby-plugin-transition-link'

import { Wrapper, Icon } from './styles.js'

const Socials = ({ data }) => {
  return (
    <Wrapper>
      {data.map(
        item =>
          item.to && (
            <li key={item.id}>
              <a href={item.to} title={item.name} target="_blank">
                <Icon src={item.icon} />
              </a>
            </li>
          )
      )}
    </Wrapper>
  )
}

Socials.propTypes = {
  fill: PropTypes.string,
}

Socials.defaultProps = {
  fill: '#000000',
}

export default Socials
