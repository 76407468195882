exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-us-js": () => import("./../../../src/pages/us.js" /* webpackChunkName: "component---src-pages-us-js" */),
  "component---src-pages-work-dato-cms-project-froodie-slug-js": () => import("./../../../src/pages/work/{datoCmsProjectFroodie.slug}.js" /* webpackChunkName: "component---src-pages-work-dato-cms-project-froodie-slug-js" */),
  "component---src-pages-work-dato-cms-project-jayne-slug-js": () => import("./../../../src/pages/work/{datoCmsProjectJayne.slug}.js" /* webpackChunkName: "component---src-pages-work-dato-cms-project-jayne-slug-js" */),
  "component---src-pages-work-dato-cms-project-oracle-slug-js": () => import("./../../../src/pages/work/{datoCmsProjectOracle.slug}.js" /* webpackChunkName: "component---src-pages-work-dato-cms-project-oracle-slug-js" */),
  "component---src-pages-work-dato-cms-project-slug-js": () => import("./../../../src/pages/work/{DatoCmsProject.slug}.js" /* webpackChunkName: "component---src-pages-work-dato-cms-project-slug-js" */),
  "component---src-pages-work-dato-cms-project-spotify-slug-js": () => import("./../../../src/pages/work/{datoCmsProjectSpotify.slug}.js" /* webpackChunkName: "component---src-pages-work-dato-cms-project-spotify-slug-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

