export const colors = {
  ghost: 'transparent',
  white: '#ffffff',
  white2: '#fafafa',
  white3: '#EAEAEA',
  black: '#000000',
  black2: '#161616',
  black3: '#181818',
  grey: '#6C6C6C',
  grey2: '#EAEAEA',
  grey3: '#EAEAEA',
  purple: '#4100F5',
  purple2: '#4100F5',
  orange: '#FF6F61',
  red: '#e6001c',
  green: '#35be25',
  background: '#ffffff',
}
